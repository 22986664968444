<template>
  <div class="my-1">
    <div class="form-control w-full">
      <label class="label">
        <span class="label-text">Title<span class="text-red-500">*</span></span>
      </label>
      <input
        v-model="serviceDetail.homepageServiceTitle"
        type="text"
        class="input input-bordered w-full"
        required
      />
    </div>

    <div class="flex flex-wrap col-wrapper">
      <div class="col">
        <div class="form-control w-full">
          <label class="label">
            <span class="label-text">Select Service</span>
          </label>
          <select
            v-model="serviceDetail.homepageService1"
            class="select select-sm select-bordered"
          >
            <option value=""></option>
            <option
              v-for="(service, index) in serviceList"
              :key="index"
              :value="service.id"
            >
              {{ service.serviceName }}
            </option>
          </select>
        </div>
      </div>

      <div class="col">
        <div class="form-control w-full">
          <label class="label">
            <span class="label-text">Select Service</span>
          </label>
          <select
            v-model="serviceDetail.homepageService2"
            class="select select-sm select-bordered"
          >
            <option value=""></option>
            <option
              v-for="(service, index) in serviceList"
              :key="index"
              :value="service.id"
            >
              {{ service.serviceName }}
            </option>
          </select>
        </div>
      </div>

      <div class="col">
        <div class="form-control w-full">
          <label class="label">
            <span class="label-text">Select Service</span>
          </label>
          <select
            v-model="serviceDetail.homepageService3"
            class="select select-sm select-bordered"
          >
            <option value=""></option>
            <option
              v-for="(service, index) in serviceList"
              :key="index"
              :value="service.id"
            >
              {{ service.serviceName }}
            </option>
          </select>
        </div>
      </div>

      <div class="col">
        <div class="form-control w-full">
          <label class="label">
            <span class="label-text">Select Service</span>
          </label>
          <select
            v-model="serviceDetail.homepageService4"
            class="select select-sm select-bordered"
          >
            <option value=""></option>
            <option
              v-for="(service, index) in serviceList"
              :key="index"
              :value="service.id"
            >
              {{ service.serviceName }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["serviceDetail", "serviceList"],
};
</script>

<style scoped>
.col-wrapper {
  margin: 0 -8px;
}

.col-wrapper .col {
  flex: 0 0 50%;
  padding: 8px;
}
</style>
