<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon
            @click="navigateToManageHomepageService"
            class="h-7 w-7 pt-2 cursor-pointer"
          />
          <h2 class="font-semibold">Add Homepage Service</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <form @submit.prevent="saveHomePageService" class="card">
        <HomepageServiceCard
          :serviceDetail="homeServiceData"
          :serviceList="serviceList"
        />

        <label class="label"
          ><span class="label-text text-black"
            >Background image for service</span
          ></label
        >
        <DragDropImage
          @changed="handleImage"
          :max="1"
          class="text-black"
          clearAll="Clear All"
          maxError="Maximum one file only"
        />

        <button type="submit" class="btn btn-success btn-block mt-3">
          Save
        </button>
      </form>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";

import DragDropImage from "../../components/DragDropImage.vue";
import HomepageServiceCard from "../../components/HomepageService/HomepageServiceCard.vue";
import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "ManageHomepageService",
  components: {
    SideNavBar,
    HomepageServiceCard,
    DragDropImage,
    ChevronLeftIcon,
  },
  data() {
    return {
      serviceList: [],
      selectedFile: [],
      homeServiceData: {
        homepageServiceTitle: "",
        homepageService1: "",
        homepageService2: "",
        homepageService3: "",
        homepageService4: "",
      },
    };
  },
  mounted() {
    this.getServiceDetail();
  },
  methods: {
    handleImage(files) {
      this.selectedFile = files[0];
    },
    async getServiceDetail() {
      try {
        let response = await this.axios({
          url: "/manageHomeService/getServiceDetail",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        this.serviceList = response.data["services"];
        this.homepageServiceList = response.data["homePageService"];
        if (this.homepageServiceList.length != 0) {
          this.homePageServiceForm = this.homepageServiceList;
        }
        this.isLoading = false;
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push({
            name: "Login",
            query: {
              error:
                "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
            },
          });
          this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
        }
      }
    },
    async saveHomePageService() {
      let formData = new FormData();
      formData.append(
        "homepageServiceTitle",
        this.homeServiceData.homepageServiceTitle == null
          ? ""
          : this.homeServiceData.homepageServiceTitle
      );
      formData.append(
        "homepageService1",
        this.homeServiceData.homepageService1
      );
      formData.append(
        "homepageService2",
        this.homeServiceData.homepageService2
      );
      formData.append(
        "homepageService3",
        this.homeServiceData.homepageService3
      );
      formData.append(
        "homepageService4",
        this.homeServiceData.homepageService4
      );
      formData.append("homepageServiceImage", this.selectedFile);

      try {
        let response = await this.axios.post(
          "/manageHomeService/addHomePageService",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.$swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "success",
          title: response.data["message"],
        });

        this.navigateToManageHomepageService();
      } catch (error) {
        if (error.response.status == 401) {
          this.$router.push({
            name: "Login",
            query: {
              error:
                "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
            },
          });
          this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
        }
      }
    },
    navigateToManageHomepageService() {
      this.$router.push({ name: "ManageHomepageService" });
    },
  },
};
</script>

<style>
</style>